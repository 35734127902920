const env = {
    apiHost: "http://localhost:8085/",
    // apiHost: "https://tictactrump.io:8085/",
    TTTusdtprice: 0.001,
    // testnet
    // tokenAddres: "0x086Da7634EBf9301ceAbAe7A85C9c46e27D5DB8D",
    // percentage: 1,
    // usdtAddres: "0xE4aB69C077896252FAFBD49EFD26B5D171A32410",
    // wethAddres: "0x4200000000000000000000000000000000000006",
    // BuyAddress: "0x46912A4a20981Fe188da9e56bB80A05eC773bA77",
    // rpc_url: "https://sepolia.base.org",
    // explorerUrl: "https://sepolia.basescan.org/",

    // mainnet
    tokenAddres: "0x74F6D06141F6E1eDE81bC9047e35E82EdffC666D",
    percentage: 1,
    usdtAddres: "0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913",
    wethAddres: "0x4200000000000000000000000000000000000006",

    BuyAddress: "0xaeb89d4d227241aC45B51A18532C72Ad614FA8C8",
    rpc_url: "https://base.llamarpc.com",
    explorerUrl: "https://basescan.org",
};


export default env;  